/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */



  export function locationName(profile) {
    if (profile.c_facilityType && profile.c_facilityType == 'Hospital') {
      return profile.name;
    } else if (profile.name.includes('Recovery Village')) {
      return profile.name;
    } else if (profile.name.includes('Miami Cancer Institute')) {
      return 'Miami Cancer Institute';
    } else if(profile.name == 'Miami Cardiac & Vascular Institute') {
      return 'Miami Cardiac & Vascular Institute';
    } else if (profile.name) {
      return profile.name;
    } else if
    (profile.c_areaName && profile.c_facilityType) {
      return profile.c_areaName + " | " + profile.c_facilityType;
    } else if
    (profile.c_areaName) {
      return profile.c_areaName;
    } else if
    (profile.c_facilityType) {
      return profile.c_facilityType;
    } else 
      return '';
  }

  export function locationName_es(profile) {
    if (profile.c_facilityTypeEnglish && profile.c_facilityTypeEnglish == 'Hospital') {
      return profile.name;
    } else if (profile.name.includes('Recovery Village')) {
      return profile.name;
    } else if (profile.name.includes('Miami Cancer Institute')) {
      return 'Miami Cancer Institute';
    } else if(profile.name == 'Miami Cardiac & Vascular Institute') {
      return 'Miami Cardiac & Vascular Institute';
    } else if
    (profile.c_areaName && profile.c_facilityTypeEnglish) {
      return profile.c_areaName + " | " + profile.c_facilityTypeEnglish;
    } else if
    (profile.c_areaName) {
      return profile.c_areaName;
    } else if
    (profile.c_facilityTypeEnglish) {
      return profile.c_facilityTypeEnglish;
    } else 
      return profile.name;
  }

    export function areaName(profile) {
      if (profile.c_areaName) {
        return ', ' + profile.c_areaName;
      } 
      return '';
    }
    export function facilityUrl(profile) {
      if (profile.c_facilityType && (profile.c_facilityType  == 'Urgent Care Express' || profile.c_facilityType  == 'Urgent Care') && profile.reservationUrl && profile.reservationUrl.url) {
        return profile.reservationUrl.url;
      } else
        return '';
    }

   export function facilityLabel(profile) {
    if (profile.c_facilityType && (profile.c_facilityType == 'Urgent Care Express' || profile.c_facilityType == 'Urgent Care') && profile.reservationUrl && profile.reservationUrl.url)  {
      return 'Request Appointment';
      } else  
      return 'Call ' +  Formatter.nationalizedPhoneDisplay(profile,'mainPhone');
    }


    export function facilityType(profile) {
      if (profile.c_facilityType) {
        return profile.c_facilityType;
      } 
      return '';
    }

    export function providerCtaLabel(isSpanish = false) {
      return isSpanish ? 'Ver Perfil' : 'View Profile';
    }

    export function providerCtaLink(profile) {
      if (profile.reservationUrl) {
        return profile.reservationUrl.url;
      } 
      return profile.c_baseURL;
    }

    export function providerCtaUrl(profile) {
      if (profile.reservationUrl) {
        return profile.reservationUrl;
      } 
      return profile.c_baseURL || profile.website;
    }
